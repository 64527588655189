require('../../node_modules/uikit/dist/js/uikit.min.js')
//import UIkit from 'uikit'

//UIkit.slider('.uk-slider', {autoplay:true});
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }


} );
//$('header').attr('uk-sticky','show-on-up: true; animation: uk-animation-slide-top;');
$('.home-center, .about-content').append($('<div class="beans d-inline-block"></div>'));
$('.beans').attr('uk-parallax','y: -10, 40');
$('.home-center, .about-content').append($('<div class="beans2 d-inline-block"></div>'));
$('.beans2').attr('uk-parallax','y: -10, 40');
$('.home-about').append($('<div class="beans3 d-inline-block"></div>'));
$('.beans3').attr('uk-parallax','y: -30, 40');

$('.contact-content').append('<div class="custom-shape-divider-top-1632907550">\n' +
    '    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">\n' +
    '        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>\n' +
    '    </svg>\n' +
    '</div>');


// Hide header on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();


    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    if (st > lastScrollTop && st > navbarHeight ){
        // Scroll Down
        $('header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('header').removeClass('nav-up').addClass('nav-down');
        }
        if (st < 300) {
            $('header').addClass('hiding');
        }
        if (st < 200) {
            $('header').removeClass('nav-down').removeClass('hiding');
        }
    }

    lastScrollTop = st;
}

$( window ).resize(function() {
    menuClass();
});
menuClass()
function menuClass() {
    if($( window ).width() < 991.98) {
        setTimeout(function() {
            $('.menu-main-menu').addClass('mobile-animate')
        }, 500);
    } else {
        $('.menu-main-menu').removeClass('mobile-animate');
    }
}
